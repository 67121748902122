import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

/*

This container will make sure the content goes all the way down to the page.
Without it some pages might e.g. have the footer in the middle of the screen since the content doesn't push the footer far down enough.
Without it pages with e.g. a grey background and which doesn't have a footer, their grey background will en abruptly in the middle of the screen.

 */
let body, footer, container, height

const FillPageContainer = ({ backgroundColor, children }) => {
	// Don't declare variables in resize event listeners for performance reasons
	// Set min height of the content to fill the page
	// Do this even if the height of header + the content + the footer is taller than the height of the screen,
	// in case the content suddenly gets shorter (e.g. if the page fills up with 16 placeholders for course cards,
	// but only 1 course card is returned from the server)
	const setHeight = () => {
		// Check if the DOM has painted the container yet, i.e. offsetTop is not 0. If it hasn't, run setHeight again on next paint until it is painted and set the height afterwards
		if (container.offsetTop === 0) {
			window.requestAnimationFrame(setHeight)
		} else {
			if (footer) {
				height = body.clientHeight - container.offsetTop - footer.scrollHeight
			} else {
				height = body.clientHeight - container.offsetTop
			}
			container.style.minHeight = height > 0 ? height + 'px' : 0
		}
	}
	useEffect(() => {
		body = document.getElementsByTagName('html')[0]
		footer = document.getElementById('pageFooter')
		container = document.getElementById('FILL_PAGE_CONTAINER')
		window.addEventListener('resize', setHeight)
		setHeight()
		return () => window.removeEventListener('resize', setHeight)
	}, [])
	return (
		<div style={{ background: backgroundColor }} id="FILL_PAGE_CONTAINER">
			{children}
		</div>
	)
}

FillPageContainer.propTypes = {
	backgroundColor: PropTypes.string, // Can be used to set the background to anything other then white
}

export default FillPageContainer
